@tailwind base;
@tailwind components;
@tailwind utilities;


* {
  box-sizing: border-box;
  padding: 0px;
  margin: 0;
  scroll-behavior: smooth;
}

body {
  background: var(--color-bg);
}

/* .App {
  background: radial-gradient(circle at 3% 21%, rgba(238, 130, 238, 1) 0%, rgba(238, 130, 238, 1) 50%, rgba(245, 243, 247, 1) 100%);
} */

a{
  color: unset;
  text-decoration: none;
}
